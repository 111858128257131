<script setup lang="ts">
    import type { Asset, AssetData, BackgroundColorsPredefined, IntroData } from '~/@types/cms';

    /**
     * renders an optional intro
     * optional defined background color (light=white, black=black, grey=lightgray, mossgreen=the woom mossgreen color)
     * optional watermark is in the background as overlay
     * the Highlight data is rendered in the mol-highlight component
     * on desktop, the direction of every 2nd highlight should be in reverse
     */
    export interface SectionProps {
        intro?: IntroData | undefined;
        watermark?: Asset;
        background?: BackgroundColorsPredefined;
        center?: boolean;
    }

    withDefaults(defineProps<SectionProps>(), {
        background: 'default',
        center: false,
    });

    const { colorMapper } = useStyle();
</script>

<template>
    <section
        :class="[colorMapper[background], 'mol-section relative py-8 md:py-10', { 'left-[calc(-50vw+50%)] w-screen': background !== 'default' }]">
        <span
            v-if="watermark"
            :class="[{ 'absolute left-0 top-0 z-0 h-full w-full bg-contain bg-center bg-no-repeat opacity-25': (watermark as AssetData)?.data.src }]"
            :style="{ backgroundImage: `url(${(watermark as AssetData)?.data.src})` }"></span>
        <div :class="[{ 'relative z-10': watermark }]">
            <mol-intro
                v-if="intro"
                :title="intro.title"
                :description="intro.subtitle"
                :center="center"
                :class="{ 'text-white': background === 'black' }" />

            <atm-grid class="mol-section-grid">
                <slot></slot>
            </atm-grid>
        </div>
    </section>
</template>
